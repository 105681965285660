import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Flip from 'react-reveal/Flip';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import FeaturedPosts from '../../components/blog/featured-posts-fr';

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/**/*.md" }
        frontmatter: { status: { eq: "published" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            category
            title
            author_name
            author_id
            cover {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

class Blog extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const { currentPage, numPages, tags } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
      currentPage - 1 === 1
        ? '/fr/blog/'
        : `/fr/blog/${(currentPage - 1).toString()}`;
    const nextPage = `/fr/blog/${(currentPage + 1).toString()}`;

    return (
      <Layout>
        <SEO
          title="Blog - Cinélangues"
          description="Bienvenue sur le Blog Cinélangues"
          pathname="/fr/blog"
        />
        <div className="page blog">
          <div className="container">
            <div className="row">
              <div className="col-sm-11 my-3 ml-2">
                <h1>
                  <i className="fal fa-bullhorn" /> Actualités
                </h1>
              </div>
            </div>
          </div>
          <div className="container mb-4">
            <div className="row">
              {posts.map(({ node }) => (
                <div className="card-blog col-sm-6 col-md-6 col-lg-4" key={node.id}>
                  <Link to={`/fr/blog${node.fields.slug}`}>
                    <Img
                      fluid={node.frontmatter.cover.childImageSharp.fluid}
                      alt={node.frontmatter.title}
                      className="card-img-top"
                    />
                  </Link>
                  <div className="card-body">
                    <Link to={`/fr/blog${node.fields.slug}`}>
                      <h3 className="card-title">{node.frontmatter.title}</h3>
                      <br />
                      <div className="badge badge-warning ml-0 mr-1 mb-1 mt-1">
                        {node.frontmatter.category}
                      </div>
                    </Link>
                    <span>{node.frontmatter.date}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*
            <div className="container page-links">
              <div className="row">
                <nav aria-label="Blog navigation" className="col-lg-12 mx-auto">
                  <ul className="pagination">
                    {!isFirst && (
                      <li className="page-item">
                        <Link to={prevPage} rel="prev" className="page-link">
                          ← Previous
                        </Link>
                      </li>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                      <li
                        key={`pagination-number${i + 1}`}
                        className="page-item"
                        style={{
                          margin: 0,
                        }}
                      >
                        <Link
                          to={`/fr/blog/${i === 0 ? '' : i + 1}`}
                          className="page-link"
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    {!isLast && (
                      <li className="page-item">
                        <Link to={nextPage} rel="next" className="page-link">
                          Next →
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          */}
        </div>
      </Layout>
    );
  }
}

export default Blog;
